<template>
  <div id="location" class="chat-location">
    <div ref="point" class="point">
      <template v-if="address">
        <div class="notice">
          <div class="cont">
            <div class="des">{{ address }}</div>
          </div>
        </div>
        <img :src="locationIcon" class="icon">
      </template>
    </div>
    <div class="center" @click="goCenter">
      <img :src="centerIcon" class="img">
    </div>
    <div id="map" class="map" />
  </div>
</template>
<script>
import { initMap } from '@/utils/map'
import centerIcon from '@/assets/location/relocate@2x.png'
import locationIcon from '@/assets/chatLocation/locate@2x.png'
export default {
  data() {
    return {
      map: null,
      AMap: null,
      AMapUI: null,
      marker: null,
      address: '',
      location: '',
      locationIcon,
      centerIcon
    }
  },
  mounted() {
    this.location = this.$route.params.location
    this.address = this.location.h5Address
    this.initMap()
  },
  methods: {
    goCenter() {
      this.map.setCenter(new this.AMap.LngLat(this.location.h5Lng, this.location.h5Lat))
    },
    async initMap() {
      const loading = this.$toast.loading({ forbidClick: true, message: '初始化...', duration: 0 })
      this.AMap = await initMap()
      loading.clear()
      this.map = new this.AMap.Map('map', {
        zoom: 15,
        center: [this.location.h5Lng, this.location.h5Lat],
        WebGLParams: {
          preserveDrawingBuffer: true
        }
      })
      const center = new this.AMap.LngLat(this.location.h5Lng, this.location.h5Lat) // 获取当前地图中心点
      this.marker = new this.AMap.Marker({
        position: center,
        anchor: 'center',
        content: this.$refs.point
      })
      this.map.add(this.marker)
    }
  }
}
</script>
  <style lang="scss" scoped>
  .chat-location {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
    .map {
      width: 100%;
      height: 100%;
    }
    .center {
      position: absolute;
      top: 50%;
      right: 7px;
      z-index: 99;
      .img {
        width: 68px;
        height: 68px;
      }
    }
    .bottom {
      position: fixed;
      bottom: 40px;
      padding: 0 20px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .point {
    position: relative;
    .notice {
      display: flex;
      align-items: center;
      position: absolute;
      top: -50px;
      left: -90px;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
      background: #fff;
      .pic {
        .img {
          width: 44px;
          height: 42px;
        }
      }
      .cont {
        padding-left: 10px;
        width: 250px;
        .title {
          font-size: 16px;
          color: #2E3135;
          font-weight: bold;
          .num {
            padding: 0 5px;
            font-size: 16px;
            color: #FF7700;
          }
        }
        .des {
          padding-top: 6px;
          font-size: 12px;
          color: #333333;
        }
      }
    }
    .icon {
      width: 100px;
      height: 100px;
    }
  }
  </style>

